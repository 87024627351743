import { createBrowserHistory } from "history";
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./index.css";
// import Home from './home'
import Provider from "./context/store";
import CustomerDashboard from './CustomerDashboard/CustomerDashboard';
import Error from "./Error/PageNotFound";
import AllReviews from "./ProductReviews/AllReviews";
import WriteAReview from "./ProductReviews/WriteAReview";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import GetApiCall from "./GetApi";
import ViewDetails from "./CustomerDashboard/ViewDetails";
// import Commingsoon from "./CommingSoon/Commingsoon";



const history = createBrowserHistory();



const Home = React.lazy(() => import('./home'));


const Productlisting = React.lazy(() => import('./Productslisting'));
const Productdetail = React.lazy(() => import('./Productdetail'));
// const Cart = React.lazy(() => import('./Cart'));
// const Account = React.lazy(() => import('./Account'));
const Profile = React.lazy(() => import('./Profile'));
const Editaddress = React.lazy(() => import('./CustomerAddressBook/EditAddress'));
const Login = React.lazy(() => import('./Login'));
const Register = React.lazy(() => import('./Register'));
const GuestCart = React.lazy(() => import('./CheckoutVerification'));
const TermsConditions = React.lazy(() => import('./Termscondition'));
const StaticPages = React.lazy(() => import('./StaticPages'));


// ==========AddressBook=============

const AddAddress = React.lazy(() => import('./CustomerAddressBook/AddAddress'));
const ChangePassword = React.lazy(() => import('./CustomerAddressBook/ChangePassword'));
const OrderHistory = React.lazy(() => import('./CustomerAddressBook/OrderHistory'));
const SideBar = React.lazy(() => import('./CustomerAddressBook/SideBar'));
const SidebarProfile = React.lazy(() => import('./CustomerAddressBook/SidebarProfile'));
const Checkout = React.lazy(() => import('./Checkout'));
const CartCheckout = React.lazy(() => import('./CartCheckout'));



// const CustomerDashboard = React.lazy(() => import('./CustomerDashboard/CustomerDashboard'));
const AddressBookForm = React.lazy(() => import('./CustomerDashboard/AddressBookForm'));
const OrderPlaced = React.lazy(() => import('./OrderPlace'));
const PaymentProcess = React.lazy(() => import('./PaymentProcess'));
const PaymentFail = React.lazy(() => import('./PaymentFail'));
const PaymentSuccess = React.lazy(() => import('./PaymentSuccess'));
const Contactus = React.lazy(() => import('./Contact-us'));
const privacyPolicy = React.lazy(() => import('./privacyPolicy'));
const Faq = React.lazy(() => import('./Faq'));
const Manage = React.lazy(() => import('./manage'));
const BlogDetails = React.lazy(() => import('./Blogs/BlogDetails'));

const Returnrefund = React.lazy(() => import('./returnrefund'));
// const Provider = React.lazy(() => import('./context/store'));
const ForgotPassword = React.lazy(() => import('./ForgetPassword'));
const ViewInvoiceForm = React.lazy(() => import('./ViewInvoiceForm'));


function App() {
    const [staticPages, setStaticPages] = useState([])

    useEffect(() => {
        if (localStorage.getItem("staticPagesList")) {
            var list = JSON.parse(localStorage.getItem("staticPagesList"))
            setStaticPages(list);
        } else {
            GetApiCall.getRequest("GetStaticPagesList").then((results) =>
                results.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                        setStaticPages(obj1.data)
                        localStorage.setItem("staticPagesList", JSON.stringify(obj1.data))
                    }
                })
            );
        }
    }, [])
    return (
        <Provider>
            <Suspense fallback={<div></div>}>
                <Router history={history}>
                    <Switch>
                        {staticPages?.map((dat, index) => {
                            return (
                                <Route path={[dat.fld_route]} key={index} component={StaticPages}></Route>
                            )
                        })}
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Commingsoon}></Route> */}
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}></Route>
                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/writereview/:reviewid`}
                            component={WriteAReview}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/all-reviews/:variantid`}
                            component={AllReviews}
                        ></Route>
                        {/* <Route
            exact
            path={`${process.env.PUBLIC_URL}/vertical/:verticalname/:categoryid`}
            component={Productlisting}
          ></Route> */}
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/ct/:categoryname/:verticalid/:categoryid`}
                            component={Productlisting}
                        ></Route>


                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/productdetail/:productname/:variantid`}
                            component={Productdetail}
                        ></Route>


                        {/* <Route
            exact
            path={`${process.env.PUBLIC_URL}/cart`}
            component={Cart}
          ></Route> */}


                        {/* <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/account`}
                            component={Account}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        ></ProtectedRoute> */}
                        {/* <Route
      exact
      path={`${process.env.PUBLIC_URL}/orders`}
      component={Orders}
    ></Route> */}
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/termsandcondition`}
                            component={TermsConditions}
                        ></Route>
                        {/* <Route
      exact
      path={`${process.env.PUBLIC_URL}/wishlist`}
      component={Wishlist}
    ></Route> */}
                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/profile`}
                            component={Profile}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/editaddress`}
                            component={Editaddress}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/changepassword`} component={Changepassword}></Route> */}

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/login`}
                            component={Login}
                        ></Route>

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/register`}
                            component={Register}
                        ></Route>

                        {/* ========Address==== */}

                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/add_address`}
                            component={AddAddress}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/address_book_form`}
                            component={AddressBookForm}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/view-details`}
                            component={ViewDetails}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/change_password`}
                            component={ChangePassword}
                        ></Route>
                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/order_history`}
                            component={OrderHistory}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/sidebar`}
                            component={SideBar}
                        ></Route>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/account_profile`}
                            component={SidebarProfile}
                        ></Route>

                        {/*=====*/}

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/checkout`}
                            component={Checkout}
                        ></Route>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/guest-cart`}
                            component={GuestCart}
                        ></Route>

                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/customerprofile`}
                            component={CustomerDashboard}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        <ProtectedRoute exact
                            path={`${process.env.PUBLIC_URL}/cart`}
                            component={CartCheckout}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            } />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/order_placed`}
                            component={OrderPlaced}

                        />
                        {/* <Route path={`${process.env.PUBLIC_URL}/orders`} component={Orders}></Route> */}

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/paymentprocess/:txnid/:mipid`}
                            component={PaymentProcess}
                        />

                        <Route
                            exact
                            path={"/paymentfail/:txnid"}
                            component={PaymentFail}
                        // isAuth={
                        //   localStorage.getItem("CustomerData") == null ? false : true
                        // }
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/paymentsuccess/:txnid`}
                            component={PaymentSuccess}
                        />

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/contact-us`}
                            component={Contactus}
                        ></Route>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/privacypolicy`}
                            component={privacyPolicy}
                        ></Route>
                        {/* <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/:pagename/:id`}
                            component={StaticPages}
                        ></Route> */}
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/faqs`}
                            component={Faq}
                        ></Route>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/returnandrefundpolicy`}
                            component={Returnrefund}
                        ></Route>

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/blogs/:blogtitle/:id`}
                            component={BlogDetails}
                        ></Route>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/forget-password`}
                            component={ForgotPassword}
                        ></Route>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/manage`}
                            component={Manage}
                        ></Route>
                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/view-invoice-form/:id/:orderid`}
                            component={ViewInvoiceForm}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        <ProtectedRoute
                            exact
                            path={`${process.env.PUBLIC_URL}/payment-processing`}
                            component={PaymentSuccess}
                            isAuth={
                                localStorage.getItem("CustomerData") == null ? false : true
                            }
                        />
                        <Route component={Error} />


                    </Switch>

                </Router>
            </Suspense>
        </Provider>

    );
}

export default App;
